.Home {
  /* display: flex; */

  /* flex-direction: column; */

  /* justify-content: flex-end; */

  /* align-items: center; */
  height: 100%;
  position: relative;
}

.Home > * {
  /* border: 1px dashed; */
  width: 80vw;
}

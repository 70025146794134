body {
  margin: 0;
  //font-family: 'Anonymous Pro', Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-family: 'Karla Variable';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(215 46 162 / 94%);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'DM Serif Text';
  font-weight: 600;
}

button {
  appearance: none;
  border: 0;
  background: none;
}

::selection {
  color: white;
  background-color: #ff7bc1;
}

a {
  color: rgb(215 46 162 / 94%);
  text-decoration: underline;

  &:hover {
    color: rgb(94 178 190 / 94%);
  }
}

strong {
  font-weight: 700;
}

@keyframes borderPulse {
  0% {
    border: 3px solid;
  }

  50% {
    border: 6px solid;
  }

  100% {
    border: 3px solid;
  }
}

html,
body,
#root {
  height: 100%;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.Job {
  max-width: 800px;
  margin: 20px auto 20px;
  padding: 20px 20px 40px 20px;
  background: rgba(255, 241, 232, 0.38);
  border-radius: 3px;
  box-shadow: 2px 2px 3px 0px rgb(255, 203, 213);;

  &:last-of-type {
    border-bottom: 0;
  }

  h3 {
    margin-bottom: 0;
  }
  h5 {
    margin-top: 0;
  }
}

.detail {
  list-style: square;
  margin: 20px 0;
  padding: 0;
  text-align: left;

  li {
    margin: 10px 0;
    position: relative;
    text-indent: 1.3em;
    &:before {
      position: absolute;
      content: '\2766';
      top: 0;
      left: -1.3em;
      color: #5eb2be;
    }
    list-style-type: none;
  }
}

.tags {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  button {
    font-family: 'Xanh Mono';
    appearance: none;
    border: 0;
    box-shadow: 2px 2px 4px 0px rgba(250, 183, 171, 0.63);
    position: relative;
    background: rgb(255, 168, 187);
    color: white;
    border-radius: 3px;
    padding: 4px;
    margin: 4px;
    font-weight: 600;
    cursor: pointer;

    transition: background-color .2s ease-in-out;

    &:hover {
      background-color: rgb(229, 142, 161);
    }
  }
}

button.active {
  background: #77bde5;
}

@media print {

  .Job {
    max-width: 80%;
  }

  .tags {
    li {
      font-weight: bold;
      box-shadow: none;
    }
  }
}
